import { useCallback, useEffect, useRef, useState } from 'react';

import { useAppDispatch } from '../../../app/redux/hooks';
import { appSubscriptionService } from '../../../common/subscriptions/appSubscriptionService';
import { TransactionLineItemAddRequest, useAddTransactionLineItemMutation } from '../../api';
import { showErrorDialog } from '../../error-dialog/errorDialogSlice';
import { setAddToTransactionInProgress } from '../orderSlice';

export default function OrderAddToTransaction() {
  const dispatch = useAppDispatch();
  const addItemList = useRef<TransactionLineItemAddRequest[]>([]);
  const [triggerAddLineItem] = useAddTransactionLineItemMutation();
  const [isAdding, setIsAdding] = useState(false);
  const [addError, setAddError] = useState<string | null>(null);

  const processNextItem = useCallback(async () => {
    if (addItemList.current.length > 0 && !isAdding) {
      setIsAdding(true);
      const transactionItem = addItemList.current.shift();
      if (transactionItem) {
        console.debug('Adding item to transaction', transactionItem);
        try {
          await triggerAddLineItem({
            menuItemId: transactionItem.menuItemId,
            unitOfSaleId: transactionItem.unitOfSaleId,
            quantity: transactionItem.quantity,
            condiments: transactionItem.condiments.map((x) => ({ menuItemId: x.menuItemId, unitOfSaleId: x.unitOfSaleId })),
            transactionId: transactionItem.transactionId,
            addOns: transactionItem.addOns.map((x) => ({ menuItemId: x.menuItemId })),
            takeOffs: transactionItem.takeOffs.map((x) => ({ menuItemId: x.menuItemId })),
            wasteReasonId: transactionItem.wasteReasonId,
            operatorId: transactionItem.operatorId,
          }).unwrap();
        } catch (error) {
          setAddError('Error adding item to transaction.');
        } finally {
          setIsAdding(false);
          if (addItemList.current.length > 0) processNextItem();
          else dispatch(setAddToTransactionInProgress(false));
        }
      }
    }
  }, [dispatch, isAdding, triggerAddLineItem]);

  useEffect(() => {
    const sub = appSubscriptionService.addToBasketObservable().subscribe((val) => {
      dispatch(setAddToTransactionInProgress(true));
      console.debug('Adding item to basket', val);
      addItemList.current.push(val);
      if (!isAdding) processNextItem();
    });
    return () => sub.unsubscribe();
  }, [dispatch, isAdding, processNextItem]);

  useEffect(() => {
    if (addError) {
      dispatch(showErrorDialog({ message: 'Error adding item', dismissible: true }));
      setAddError(null);
    }
  }, [addError, dispatch]);

  /*useEffect(() => {
    if (isAdding) {
      while (addItemList.current.length > 0) {
        const transactionItem = addItemList.current.shift();
        if (transactionItem) {
          triggerAddLineItem({
            menuItemId: transactionItem.menuItemId,
            unitOfSaleId: transactionItem.unitOfSaleId,
            quantity: transactionItem.quantity,
            condiments: transactionItem.condiments.map((x) => ({ menuItemId: x.menuItemId, unitOfSaleId: x.unitOfSaleId })),
            transactionId: transactionItem.transactionId,
            addOns: transactionItem.addOns.map((x) => ({ menuItemId: x.menuItemId })),
            takeOffs: transactionItem.takeOffs.map((x) => ({ menuItemId: x.menuItemId })),
          })
            .unwrap()
            .then(() => {
              //dispatch(setAddToTransactionInProgress(false));
              //dispatch(removeAddItem(firstItem.uniqueId));
            })
            .catch(() => {
              //showErrorDialog({ message: 'Error adding item', dismissible: true });
              //dispatch(setAddToTransactionInProgress(false));
              // dispatch(removeAddItem(firstItem.uniqueId));
            });
        }
      }
    }
  }, [isAdding]);*/

  // useEffect(() => {
  /*  if (addItems.length > 0) {
      const firstItem = addItems[0];
      const transactionItem = firstItem.item;
      triggerAddLineItem({
        menuItemId: transactionItem.menuItemId,
        unitOfSaleId: transactionItem.unitOfSaleId,
        quantity: transactionItem.quantity,
        condiments: transactionItem.condiments.map((x) => ({ menuItemId: x.menuItemId, unitOfSaleId: x.unitOfSaleId })),
        transactionId: transactionItem.transactionId,
        addOns: transactionItem.addOns.map((x) => ({ menuItemId: x.menuItemId })),
        takeOffs: transactionItem.takeOffs.map((x) => ({ menuItemId: x.menuItemId })),
      })
        .unwrap()
        .then(() => {
          dispatch(setAddToTransactionInProgress(false));
          dispatch(removeAddItem(firstItem.uniqueId));
        })
        .catch(() => {
          showErrorDialog({ message: 'Error adding item', dismissible: true });
          dispatch(setAddToTransactionInProgress(false));
          dispatch(removeAddItem(firstItem.uniqueId));
        });
      //dispatch(removeAddItem(firstItem.uniqueId));
    }*/
  //    console.warn('addItems', addItems);
  //}, [addItems, dispatch, triggerAddLineItem]);
  return <></>;
}
